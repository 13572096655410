import* as M from "@materializecss/materialize";

// Init materialize
M.Sidenav.init(document.querySelectorAll('.sidenav'), {});
M.Modal.init(document.querySelectorAll('.modal'), {});

// Gloabl vars, meh..
let socket;
let toast;
let players;
let points;

// Websocket setup
const socketConnect = () => {
  const proto = (location.protocol == 'https:') ? 'wss' : 'ws';
  const socket_reconnect_interval = 1000;
  socket = new WebSocket(`${proto}://${location.host}/ws`);

  // Join room on websocket open
  socket.addEventListener('open', () => {
    console.log('websocket open');
    if (toast) {
      toast.dismiss();
    }
    toast = undefined;
    socket.send(JSON.stringify({
      action: 'join',
      room: location.pathname.split('/')[2],
    }));
  });

  // Handle socket error
  socket.addEventListener('error', (e) => {
    console.log('websocket error', e);
  });

  // Handle socket close
  socket.addEventListener('close', () => {
    console.log('websocket close');
    if (toast === undefined) {
      toast = new M.Toast({
        text: 'Websocket disconnected',
        classes: 'red lighten-1',
        displayLength: Infinity,
      });
    }
    setTimeout(socketConnect, socket_reconnect_interval);
  });

  // Handle incoming websocket messages
  socket.addEventListener('message', (e) => {
    let msg = JSON.parse(e.data);
    switch (msg.action) {
       case 'error':
        new M.Toast({
          text: msg.error,
          classes: 'red lighten-1',
        });
        break;

      case 'state':
        players = msg.state.players;
        points = msg.state.points;
        if (Object.keys(players).length > 0) {
          document.getElementById('scoreboard').innerHTML = '';
          render();
        } else {
          let motd = document.createElement('h4');
          motd.classList.add('center')
          motd.innerHTML = 'There are no players, add some to get started';
          let scoreboard = document.getElementById('scoreboard')
          scoreboard.innerHTML = '';
          scoreboard.appendChild(motd);
        }
        break;

      case 'score':
      case 'sub':
        setMark(document.getElementById(`${msg.player}-${msg.point}`), msg.value);
        setTotal(msg.player, msg.total);
      break;
    }
  });
}


// Handle new player additions
document.getElementById('add-player-submit').addEventListener('click', () => {
  let input = document.getElementById('add-player-input');
  socket.send(JSON.stringify({
    action: 'add-player',
    player: input.value,
  }));
  input.value = '';
});


// Reset game
document.getElementById('reset-players').addEventListener('click', () => {
  socket.send(JSON.stringify({
    action: 'reset-players',
  }));
});


// Reset score
document.getElementById('reset-score').addEventListener('click', () => {
  socket.send(JSON.stringify({
    action: 'reset-score',
  }));
});

// Randomize
document.getElementById('randomize').addEventListener('click', () => {
  socket.send(JSON.stringify({
    action: 'randomize',
  }));
});


// Get list of the player ids sorted by position
const playersSorted = () => {
  return Object.keys(players).sort((x, y) => {
    if (players[x].position < players[y].position) {
      return -1;
    }
    if (players[x].position > players[y].position) {
      return 1;
    }
    return 0
  });
}


// (Re)render the scoreboard ui
// Builds rows and update the scoreboard
const render = () => {
  let scoreboard = document.getElementById('scoreboard');
  let rows = ['players', ...points, 'Bull', 'score'];
  rows.forEach((flavor) => {
    scoreboard.appendChild(renderRow(flavor));
  });

  // Set the total score
  Object.keys(players).forEach(player => {
    setTotal(player, players[player].total);
  });
}


// Build and return a row (div) html node of the specified flavor
const renderRow = (flavor) => {
  // Get player ids sorted
  let keys = playersSorted();
  let maxPlayersPerSide = Math.ceil(keys.length / 2);

  // Determine spacing
  const colsPerSide = 5;
  const maxColWidth = 2;
  const totalCols = 12;
  let colSpan = (maxPlayersPerSide * maxColWidth > colsPerSide) ? 1 : 2;
  if (colSpan == 2 && window.innerWidth < 600 && keys.length < 3) {
    colSpan = 3;
  }

  // Build row
  let row = document.createElement('div');
  row.classList.add('row', 'valign-wrapper');


  let s = colsPerSide - (maxPlayersPerSide * colSpan);
  let col = document.createElement('div');
  if (s !== 0) {
  col.classList.add('col', 'center', `s${s}`);
  }
  console.log(maxPlayersPerSide, s)
  row.appendChild(col)


  // Add colums for each player
  keys.forEach((id, i) => {
    // Build column
    let col = document.createElement('div');
    col.classList.add('col', 'center', `s${colSpan}`);

    switch(flavor) {
      case 'players':
        row.classList.add('players');
        let name = document.createElement('div');
        name.innerHTML = players[id].name;
        name.classList.add('player-name');
        col.appendChild(name);
        break;

      case 'score':
        row.classList.add('point-row');
        let score = document.createElement('div');
        score.setAttribute('id', `${id}-score`);
        col.appendChild(score);
        break;

      default:
        // The rest of the points rows
        const point = flavor;
        row.classList.add('point-row');
        let tick = document.createElement('div');
        tick.setAttribute('id', `${id}-${point}`);
        tick.classList.add('score-toggle');
        setMark(tick, players[id].score[point]);

        // Add event listeners click and right click (contextmenu)
        // for changing score
        tick.addEventListener('click', (e) => {
          socket.send(JSON.stringify({
            action: 'score',
            player: id,
            point: point
          }));
        });

        tick.addEventListener('contextmenu', (e) => {
          e.preventDefault();
          socket.send(JSON.stringify({
            action: 'sub',
            player: id,
            point: point
          }));
        });

        col.appendChild(tick)
    }

    // Collect the columns
    row.appendChild(col);

    // Add center label column in the middle
    if (i + 1 === maxPlayersPerSide) {
      let labelDiv = document.createElement('div')
      labelDiv.classList.add('col', 's2', 'center', 'label');
      if (!(flavor === 'score' || flavor === 'players')) {
        labelDiv.innerHTML = flavor;
      }
      row.appendChild(labelDiv);
    }
  });

  return row;
}


// Set the score marker for marker to the provided val
const setMark = (target, val) => {
  target.innerHTML = '';
  if (val === 0) {
    // Display just the add tick mark
    let mark = document.createElement('span');
    mark.innerHTML = 'add';
    mark.classList.add('tick', 'material-symbols-outlined', 'add');
    target.appendChild(mark);
  } else {

    // Display tick marks
    for (let i = 1; i <= val && i <= 3; i++) {
      let mark = document.createElement('span');
      let innerHTML;
      if (i === 1 || i === 2) {
        innerHTML = 'pen_size_2';
      } else if (i === 3) {
        innerHTML = 'brightness_1'
      }
      mark.innerHTML = innerHTML;
      mark.classList.add('tick', 'material-symbols-outlined', `tick-${i}`);
      target.appendChild(mark);
    }

    // Display points if exists
    if (val > 3) {
      let point = document.createElement('span');
      point.classList.add('point')
      point.innerHTML = `${val-3}`;
      target.appendChild(point);
    }
  }
}


// Display total sum of points
const setTotal = (player, val) => {
  let total = document.getElementById(`${player}-score`);
  total.classList.add('center', 'label', 'total');
  if (val > 0) {
    total.innerHTML = val;
  } else {
    total.innerHTML = '';
  }
}


// Open the socket and lets go!
socketConnect();